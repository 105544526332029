import './public-path';
import React from 'react';
import { createRoot } from 'react-dom/client';
import dva from 'dva';
import createLoading from 'dva-loading';
import { createBrowserHistory } from 'history';
import { createLogger } from 'redux-logger';
import { ConfigProvider } from 'antd';
import { init } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import dayjs from 'dayjs';
import dayjsPluginUtc from 'dayjs/plugin/utc';
import dayjsPluginTimezone from 'dayjs/plugin/timezone';
import dayjsPluginCustomParseFormat from 'dayjs/plugin/customParseFormat';
import dayjsPluginIsSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import dayjsPluginIsSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import dayjsPluginAdvancedFormat from 'dayjs/plugin/advancedFormat';
import dayjsPluginIsToday from 'dayjs/plugin/isToday';
import { init as initWsBizHooks } from '@helloworld1812/ws-biz-hooks';
import { setNavigateFn } from '@helloworld1812/ws-router';
import ReactModal from 'react-modal';

import 'react-sliding-pane/dist/react-sliding-pane.css';
import 'react-intl-tel-input/dist/main.css';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/translucent.css';

import 'react-quill/dist/quill.snow.css';

import '@/index.css';
import '@/antd2.css';
import '@/antd4.css';
import '@/index.less';

import { handleGlobalError } from '@/utils/dva';
import { navigateFn } from '@/utils/nav';
import {
  APIHOST,
  DEPLOY_ENV,
  SENTRY_RELEASE,
  SENTRY_DSN,
  ENABLE_REDUX_LOGGER,
  ENABLE_MFE,
  WS_DISABLE_WEBSOCKET,
} from '@/config';
import { initI18n } from '@/root/i18n';

import router from './root/router';
import { ignoreAntd2ConsoleWarningsAndErrors } from './tests/antd2-compatible-console';
import { registerAllModels } from './models';

function setupSentry() {
  init({
    dsn: SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler for finer control of sentry
    tracesSampleRate: 0.005,
    release: SENTRY_RELEASE,
    beforeSend: (event) => {
      if (event?.exception?.values?.[0]?.type === 'UnhandledRejection') return null;
      return event;
    },
    denyUrls: [
      // https://sentry.io/organizations/workstream-is/issues/2369623261/?project=5739186&query=is%3Aunresolved&statsPeriod=14d
      /intercomcdn\.com/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /googlemapsapi\.com/i,
    ],
  });
}

async function setupMsw() {
  const { getWorker } = await import('@/tests/server/browser-server');

  window.msw = {
    start() {
      getWorker().start({ onUnhandledRequest: 'bypass' });
    },
    stop() {
      getWorker().stop();
    },
  };

  console.info('[msw] worker is ready and bound to `window.msw`.');
}

function startApp() {
  ConfigProvider.config({ prefixCls: 'ant4', iconPrefixCls: 'ant4icon' });
  ReactModal.setAppElement('#subapp_root_hr');

  dayjs.extend(dayjsPluginUtc);
  dayjs.extend(dayjsPluginTimezone);
  dayjs.extend(dayjsPluginCustomParseFormat);
  dayjs.extend(dayjsPluginIsSameOrBefore);
  dayjs.extend(dayjsPluginIsSameOrAfter);
  dayjs.extend(dayjsPluginAdvancedFormat);
  dayjs.extend(dayjsPluginIsToday);

  initWsBizHooks({
    apiPrefix: APIHOST,
    currentEnv: DEPLOY_ENV,
  });

  setNavigateFn(navigateFn);

  initI18n();
  ignoreAntd2ConsoleWarningsAndErrors();

  const browserHistory = createBrowserHistory({
    basename: process.env.ENABLE_MFE === 'true' ? process.env.NX_MFE_SUB_APP_ROUTE_PREFIX_HR : '',
  });

  // 1. Initialize
  const app = dva({
    ...(ENABLE_MFE && { history: browserHistory }),
    ...createLoading({
      effects: true,
    }),
    ...(ENABLE_REDUX_LOGGER && { onAction: createLogger() }),
    onError(error) {
      handleGlobalError(error);
    },
  });

  window.dvaApp = app;

  // 2. Plugins
  // app.use({});

  if (DEPLOY_ENV === 'production') {
    setupSentry();
  }

  // 3. Model
  registerAllModels(app);

  // 4. Router
  app.router(router);

  // 5. Start
  const DvaApp = app.start();

  // 6. setup mock server
  if (process.env.NODE_ENV === 'development') {
    setupMsw();
  }

  window.store = app._store;

  const {
    _store: { dispatch },
  } = app;

  async function setupWebSocket() {
    const { create: webSocketCreate, run: webSocketRun } = await import('@/services/web-socket');
    webSocketCreate(dispatch);
    webSocketRun();
  }

  if (!WS_DISABLE_WEBSOCKET) {
    setupWebSocket();
  }

  return DvaApp;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function render(props: Record<string, any>) {
  const { container } = props;

  const DvaApp = startApp();

  const root = createRoot(
    container ? container.querySelector('#subapp_root_hr') : document.querySelector('#subapp_root_hr'),
  );

  root.render(<DvaApp />);
}

if (!window.__POWERED_BY_QIANKUN__) {
  render({});
}
